import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import theme from "../styles/theme"
import { darken } from "polished"
import TrackVisibility from "react-on-screen"
import { CenteredBox, FadeScale, FadeUp } from "../styles/ui"
import spatter from "../images/spatter.png"
import Section from "./Section"

const EmbedContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: ${(9 / 16) * 100}%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`
const VideoBlockSection = styled(Section)`
  text-align: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url(${spatter});
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  @media (max-width: 800px) {
    &:before {
      transform: scale(-1);
    }
    padding: 2em 0 4em;
  }
  @media (max-width: 500px) {
    padding-bottom: 0;
  }
  .videoWrapper {
    width: 960px;
    max-width: 100%;
    margin: auto;
    background: black;
    position: relative;
    z-index: 1;
  }
  video {
    outline: none;
    display: block;
  }
`

const VideoCenteredBox = styled(CenteredBox)`
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`

const VideoBlock = ({ title, subtitle, videoUrl, videoTitle, autoplay }) => {
  const autoplayParam = autoplay ? "?autoplay=1" : ""
  return (
    <VideoBlockSection background={darken(0.06, theme.bg)}>
      <VideoCenteredBox>
        <div className="section-head-wrap">
          <TrackVisibility>
            <FadeUp>
              <h2>{title}</h2>
            </FadeUp>
          </TrackVisibility>
          <TrackVisibility>
            <FadeUp>
              <p className="sub-head">{subtitle}</p>
            </FadeUp>
          </TrackVisibility>
        </div>
        <TrackVisibility partialVisibility>
          <FadeScale>
            <div className="videoWrapper">
              <EmbedContainer>
                <iframe
                  width="960"
                  height="540"
                  src={`${videoUrl}${autoplayParam}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  title={videoTitle}
                ></iframe>
              </EmbedContainer>
            </div>
          </FadeScale>
        </TrackVisibility>
      </VideoCenteredBox>
    </VideoBlockSection>
  )
}

VideoBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
}

export default VideoBlock
