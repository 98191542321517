import React from "react"
import Layout from "../components/Layout"
import banner from "../images/banner/hq_banner2.jpg"
import { Button } from "../styles/ui"
import { RiMailSendLine } from "react-icons/ri"
import Article from "../components/Article"
import VideoBlock from "../components/VideoBlock"
import Map from "../components/Map"
import TrackVisibility from "react-on-screen"
import { FadeUp, CenteredBox } from "../styles/ui"
import StickyCTA from "../components/StickyCTA"
import { email } from "../content/content"
import rocketOnStick from "../images/rocket_on_stick.png"
import fullsize1 from "../images/gallery/hq1.jpg"
import fullsize2 from "../images/gallery/hq2.jpg"
import fullsize3 from "../images/gallery/hq3.jpg"
import fullsize4 from "../images/gallery/hq4.jpg"
import fullsize7 from "../images/gallery/hq7.jpg"
import fullsize8 from "../images/gallery/hq8.jpg"
import fullsize12 from "../images/gallery/hq12.jpg"
import fullsize13 from "../images/gallery/hq13.jpg"
import fullsize14 from "../images/gallery/hq14.jpg"
import fullsize15 from "../images/gallery/hq15.jpg"
import thumbnail1 from "../images/thumbnails/hq1.jpg"
import thumbnail2 from "../images/thumbnails/hq2.jpg"
import thumbnail3 from "../images/thumbnails/hq3.jpg"
import thumbnail4 from "../images/thumbnails/hq4.jpg"
import thumbnail7 from "../images/thumbnails/hq7.jpg"
import thumbnail8 from "../images/thumbnails/hq8.jpg"
import thumbnail12 from "../images/thumbnails/hq12.jpg"
import thumbnail13 from "../images/thumbnails/hq13.jpg"
import thumbnail14 from "../images/thumbnails/hq14.jpg"
import thumbnail15 from "../images/thumbnails/hq15.jpg"
import Gallery from "../components/Gallery"
import IconList from "../components/IconList"
import {
  FaVideo,
  FaHeadphones,
  FaCamera,
  FaLaptopCode,
  FaPaintBrush,
  FaTshirt,
  FaHorse,
  FaCrow,
  FaCrosshairs,
  FaHammer,
  FaGuitar,
  FaCube,
  FaFlask,
  FaFillDrip,
} from "react-icons/fa"
import Section from "../components/Section"
import { darken } from "polished"
import theme from "../styles/theme"
import Banner from "../components/Banner"
import HalfBlock from "../components/HalfBlock"
import SEOpt from "../components/SEOpt"
import WrapFadeUp from "../components/WrapFadeUp"

const iconListItems = [
  {
    text: "video production", //FaVideo
    icon: <FaVideo />,
  },
  {
    text: "DJ set recordings", //FaHeadphones // BsSpeaker //FaCompactDisc
    icon: <FaHeadphones />,
  },
  {
    text: "photography", //FaCamera
    icon: <FaCamera />,
  },
  {
    text: "development & design", // FaLaptopCode
    icon: <FaLaptopCode />,
  },
  {
    text: "visual arts", //FaPaintBrush
    icon: <FaPaintBrush />,
  },
  {
    text: "fashion design", //FaTshirt
    icon: <FaTshirt />,
  },
  {
    text: "concrete casting", //FaCube
    icon: <FaCube />,
  },
  {
    text: "band practice", //FaMusic FaGuitar FaDrum
    icon: <FaGuitar />,
  },
  {
    text: "3D printing", //FaHorse //FaCrow
    icon: <FaHorse />,
  },
  {
    text: "resin printing", //FaHorse //FaCrow
    icon: <FaFillDrip />,
  },
  {
    text: "laser engraving", //FaCrosshairs
    icon: <FaCrosshairs />,
  },
  {
    text: "building things out of floor",
    icon: <FaHammer />, //FaTools //FaHammer
  },
  {
    text: "good talks",
    icon: <FaCrow />, //FaTools //FaHammer
  },
  {
    text: "various experiments",
    icon: <FaFlask />, //FaTools //FaHammer
  },
]

const galleryItems = [
  {
    alt: "Warehome <3",
    fullsize: fullsize1,
    thumbnail: thumbnail1,
  },
  {
    alt: "View from the DJ booth",
    fullsize: fullsize2,
    thumbnail: thumbnail2,
  },
  {
    alt: "Office space",
    fullsize: fullsize3,
    thumbnail: thumbnail3,
  },
  {
    alt: "Tool box",
    fullsize: fullsize4,
    thumbnail: thumbnail4,
  },
  {
    alt: "Behind the scenes where we keep floor and other good things",
    fullsize: fullsize7,
    thumbnail: thumbnail7,
  },
  {
    alt: "Dog. Is a good boy",
    fullsize: fullsize8,
    thumbnail: thumbnail8,
  },
  {
    alt: "DJ booth",
    fullsize: fullsize12,
    thumbnail: thumbnail12,
  },
  {
    alt: "Little rocket by @everydaylifewithtrish",
    fullsize: fullsize13,
    thumbnail: thumbnail13,
  },
  {
    alt: "We also have a band",
    fullsize: fullsize14,
    thumbnail: thumbnail14,
  },
  {
    alt: "Awesome sign Tamara made. Tamara proud",
    fullsize: fullsize15,
    thumbnail: thumbnail15,
  },
]

const bannerImage = {
  src: banner,
  altText: "Rocketship HQ",
}

const RocketshipHQ = () => {
  return (
    <Layout>
      <SEOpt title="Rocketship HQ" />
      <Banner {...bannerImage} />
      <Section background={darken(0.03, theme.bg)}>
        <Article>
          <TrackVisibility>
            <FadeUp>
              <h1>Rocketship Warehome</h1>
            </FadeUp>
          </TrackVisibility>
          <TrackVisibility>
            <FadeUp>
              <p className="subhead">Our secret lair in Amsterdam</p>
            </FadeUp>
          </TrackVisibility>
          <TrackVisibility>
            <FadeUp>
              <p>
                Rocketship HQ (a.k.a. the Warehome) is where all of our project
                work happens: from video and music production to design,
                development, and physical making of whatever we need in the
                creative process.
              </p>
            </FadeUp>
          </TrackVisibility>
          <TrackVisibility>
            <FadeUp>
              <p>
                We offer businesses and individuals the opportunity to rent our
                space for production or filming purposes. If you'd like to know
                more about our location, equipment or rental service, or would
                like to collaborate on a project, feel free to contact us via{" "}
                <a href={`mailto:${email}`}>info@rocketshiptrips.com</a>.
              </p>
            </FadeUp>
          </TrackVisibility>
        </Article>
      </Section>
      <Section>
        <CenteredBox>
          <div className="section-head-wrap">
            <TrackVisibility>
              <FadeUp>
                <h2>What happens at HQ</h2>
              </FadeUp>
            </TrackVisibility>
          </div>
          <IconList items={iconListItems} />
        </CenteredBox>
      </Section>
      <Gallery items={galleryItems} />
      <StickyCTA
        text="Love this space?"
        buttonText="Get in touch"
        subject="Rocketship HQ"
      />
      <Section
        background={darken(0.02, theme.bg)}
        noPaddingBottom
        id="mapWrapperSection"
      >
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Location</h1>
            </WrapFadeUp>
          </div>
        </CenteredBox>
        <Map />
      </Section>
      <HalfBlock
        background={darken(0.03, theme.bg)}
        imgSrc={rocketOnStick}
        title="Let's work"
        titleLarger="together"
        text="We're always interested in cool new projects - would you like
        to collaborate?"
        cta={
          <Button as="a" href={`mailto:${email}?subject=Let's collaborate!`}>
            Get in touch <RiMailSendLine />
          </Button>
        }
      />
      <VideoBlock
        title="Recorded at Rocketship HQ"
        subtitle="DJ set by Alex Sharp filmed and recorded at Rocketship Warehome"
        videoTitle="Set For Love 2020 by Alex Sharp"
        videoUrl="https://www.youtube.com/embed/PmA6AE0cOEM"
        autoplay={false}
      />
    </Layout>
  )
}

export default RocketshipHQ
