import React from "react"
import styled, { css } from "styled-components"
import { FadeScale } from "../styles/ui"
import { Grid, Row, Col } from "@zendeskgarden/react-grid"
import fonts from "../styles/fonts"
import { FadeUp } from "../styles/ui"
import TrackVisibility from "react-on-screen"
import Section from "./Section"

const HalfBlockSection = styled(Section)`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${props =>
    !!props.fullwidth &&
    css`
      padding: 0;
    `};
  @media (max-width: 991px) {
    text-align: ${props => (!!props.fullwidth ? "left" : "center")};
    img {
      max-width: ${props => (!!props.fullwidth ? "none" : "80%")};
      margin-top: ${props => (!!props.fullwidth ? "0" : "2em")};
      max-height: 50vh;
    }
  }
  .inner {
    position: relative;
    z-index: 1;
  }
  img {
    width: ${props => (!!props.fullwidth ? "100%" : "auto")};
  }
  .text-wrap {
    max-width: 80%;
    margin: auto;
    padding: ${props => (!!props.fullwidth ? "3em" : "2em 1em 1em")};
    font-size: ${props => (!!props.fullwidth ? "80%" : "100%")};
    @media (min-width: 768px) and (max-width: 1020px) {
      font-size: ${props => (!!props.fullwidth ? "75%" : "inherit")};
    }
    @media (max-width: 410px) {
      max-width: 100%;
    }
  }
  .text-box {
    max-width: 700px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: 764px) {
      padding: 2em;
    }
  }
  h2 {
    font-family: ${props => (!!props.fullwidth ? fonts.heading : fonts.hand)};
    font-size: ${props => (!!props.fullwidth ? "2em" : "3em")};
    font-weight: normal;
    margin-bottom: 0.5em;
    line-height: 1.1;
    span {
      display: block;
      font-size: 120%;
    }
  }
  p {
    line-height: 2;
  }
  .btn-wrap {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`

const HalfBlock = ({
  imgSrc,
  title,
  titleLarger,
  text,
  cta,
  background,
  backgroundImage,
  fullwidth,
  imgOnRight,
  textBlock,
}) => {
  return (
    <HalfBlockSection
      background={background}
      backgroundImage={backgroundImage}
      fullwidth={fullwidth}
    >
      <Grid gutters={false}>
        <Row alignItems="center">
          <Col
            lg
            orderLg={imgOnRight ? 2 : "auto"}
            style={{ overflow: "hidden" }}
          >
            <TrackVisibility partialVisibility>
              <FadeScale>
                <img src={imgSrc} alt={title} className="hover-zoom" />
              </FadeScale>
            </TrackVisibility>
          </Col>
          <Col lg style={{ overflow: "hidden" }}>
            <div className="text-wrap">
              <TrackVisibility>
                <FadeUp>
                  <h2>
                    {title} {titleLarger && <span>{titleLarger}</span>}
                  </h2>
                </FadeUp>
              </TrackVisibility>
              <TrackVisibility>
                <FadeUp>
                  <p>{text}</p>
                  {textBlock}
                </FadeUp>
              </TrackVisibility>
              {cta && (
                <TrackVisibility>
                  <FadeUp>
                    <div className="btn-wrap">{cta}</div>
                  </FadeUp>
                </TrackVisibility>
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </HalfBlockSection>
  )
}

export default HalfBlock
